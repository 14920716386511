import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c27039be = () => interopDefault(import('../../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _fe340a4e = () => interopDefault(import('../../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0e76004a = () => interopDefault(import('../../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _927ffd70 = () => interopDefault(import('../../pages/editions/index.vue' /* webpackChunkName: "pages/editions/index" */))
const _41411a16 = () => interopDefault(import('../../pages/events-and-webinars/index.vue' /* webpackChunkName: "pages/events-and-webinars/index" */))
const _1ad1ac1e = () => interopDefault(import('../../pages/trainers/index.vue' /* webpackChunkName: "pages/trainers/index" */))
const _627a7958 = () => interopDefault(import('../../pages/page-not-available-in-language.vue' /* webpackChunkName: "pages/page-not-available-in-language" */))
const _b59bedfe = () => interopDefault(import('../../pages/checkout/confirmation/index.vue' /* webpackChunkName: "pages/checkout/confirmation/index" */))
const _56eee572 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77d79a59 = () => interopDefault(import('../../pages/knowledge/q-and-a/_slug.vue' /* webpackChunkName: "pages/knowledge/q-and-a/_slug" */))
const _11e342da = () => interopDefault(import('../../pages/courses/_slug.vue' /* webpackChunkName: "pages/courses/_slug" */))
const _3f8a78ce = () => interopDefault(import('../../pages/events-and-webinars/_slug.vue' /* webpackChunkName: "pages/events-and-webinars/_slug" */))
const _1e3eeeae = () => interopDefault(import('../../pages/trainers/_slug.vue' /* webpackChunkName: "pages/trainers/_slug" */))
const _24842be7 = () => interopDefault(import('../../pages/knowledge/_category/_slug.vue' /* webpackChunkName: "pages/knowledge/_category/_slug" */))
const _17f82821 = () => interopDefault(import('../../pages/courses/_category/_slug.vue' /* webpackChunkName: "pages/courses/_category/_slug" */))
const _239df27f = () => interopDefault(import('../../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _c27039be,
    name: "knowledge___fr"
  }, {
    path: "/checkout",
    component: _fe340a4e,
    name: "checkout___en"
  }, {
    path: "/checkout",
    component: _fe340a4e,
    name: "checkout___nl"
  }, {
    path: "/checkout",
    component: _fe340a4e,
    name: "checkout___fr"
  }, {
    path: "/checkout",
    component: _fe340a4e,
    name: "checkout___de"
  }, {
    path: "/checkout",
    component: _fe340a4e,
    name: "checkout___en-IN"
  }, {
    path: "/courses",
    component: _0e76004a,
    name: "courses___en"
  }, {
    path: "/courses",
    component: _0e76004a,
    name: "courses___en-IN"
  }, {
    path: "/editions",
    component: _927ffd70,
    name: "editions___en"
  }, {
    path: "/editions",
    component: _927ffd70,
    name: "editions___nl"
  }, {
    path: "/editions",
    component: _927ffd70,
    name: "editions___fr"
  }, {
    path: "/editions",
    component: _927ffd70,
    name: "editions___de"
  }, {
    path: "/editions",
    component: _927ffd70,
    name: "editions___en-IN"
  }, {
    path: "/evenements-et-webinars",
    component: _41411a16,
    name: "events-and-webinars___fr"
  }, {
    path: "/events-and-webinars",
    component: _41411a16,
    name: "events-and-webinars___en"
  }, {
    path: "/events-and-webinars",
    component: _41411a16,
    name: "events-and-webinars___nl"
  }, {
    path: "/events-and-webinars",
    component: _41411a16,
    name: "events-and-webinars___en-IN"
  }, {
    path: "/events-und-webinars",
    component: _41411a16,
    name: "events-and-webinars___de"
  }, {
    path: "/formateurs",
    component: _1ad1ac1e,
    name: "trainers___fr"
  }, {
    path: "/formations",
    component: _0e76004a,
    name: "courses___fr"
  }, {
    path: "/knowledge",
    component: _c27039be,
    name: "knowledge___en"
  }, {
    path: "/knowledge",
    component: _c27039be,
    name: "knowledge___nl"
  }, {
    path: "/knowledge",
    component: _c27039be,
    name: "knowledge___de"
  }, {
    path: "/knowledge",
    component: _c27039be,
    name: "knowledge___en-IN"
  }, {
    path: "/page-not-available-in-language",
    component: _627a7958,
    name: "page-not-available-in-language___en"
  }, {
    path: "/page-not-available-in-language",
    component: _627a7958,
    name: "page-not-available-in-language___nl"
  }, {
    path: "/page-not-available-in-language",
    component: _627a7958,
    name: "page-not-available-in-language___fr"
  }, {
    path: "/page-not-available-in-language",
    component: _627a7958,
    name: "page-not-available-in-language___de"
  }, {
    path: "/page-not-available-in-language",
    component: _627a7958,
    name: "page-not-available-in-language___en-IN"
  }, {
    path: "/trainer",
    component: _1ad1ac1e,
    name: "trainers___de"
  }, {
    path: "/trainers",
    component: _1ad1ac1e,
    name: "trainers___en"
  }, {
    path: "/trainers",
    component: _1ad1ac1e,
    name: "trainers___nl"
  }, {
    path: "/trainers",
    component: _1ad1ac1e,
    name: "trainers___en-IN"
  }, {
    path: "/trainingen",
    component: _0e76004a,
    name: "courses___nl"
  }, {
    path: "/trainings",
    component: _0e76004a,
    name: "courses___de"
  }, {
    path: "/checkout/confirmation",
    component: _b59bedfe,
    name: "checkout-confirmation___en"
  }, {
    path: "/checkout/confirmation",
    component: _b59bedfe,
    name: "checkout-confirmation___nl"
  }, {
    path: "/checkout/confirmation",
    component: _b59bedfe,
    name: "checkout-confirmation___fr"
  }, {
    path: "/checkout/confirmation",
    component: _b59bedfe,
    name: "checkout-confirmation___de"
  }, {
    path: "/checkout/confirmation",
    component: _b59bedfe,
    name: "checkout-confirmation___en-IN"
  }, {
    path: "/",
    component: _56eee572,
    name: "index___en"
  }, {
    path: "/",
    component: _56eee572,
    name: "index___nl"
  }, {
    path: "/",
    component: _56eee572,
    name: "index___fr"
  }, {
    path: "/",
    component: _56eee572,
    name: "index___de"
  }, {
    path: "/",
    component: _56eee572,
    name: "index___en-IN"
  }, {
    path: "/articles/q-and-a/:slug",
    component: _77d79a59,
    name: "knowledge-q-and-a-slug___fr"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _77d79a59,
    name: "knowledge-q-and-a-slug___en"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _77d79a59,
    name: "knowledge-q-and-a-slug___nl"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _77d79a59,
    name: "knowledge-q-and-a-slug___de"
  }, {
    path: "/knowledge/q-and-a/:slug?",
    component: _77d79a59,
    name: "knowledge-q-and-a-slug___en-IN"
  }, {
    path: "/courses/:slug",
    component: _11e342da,
    name: "courses-slug___en"
  }, {
    path: "/courses/:slug",
    component: _11e342da,
    name: "courses-slug___en-IN"
  }, {
    path: "/evenements-et-webinars/:slug",
    component: _3f8a78ce,
    name: "events-and-webinars-slug___fr"
  }, {
    path: "/events-and-webinars/:slug",
    component: _3f8a78ce,
    name: "events-and-webinars-slug___en"
  }, {
    path: "/events-and-webinars/:slug",
    component: _3f8a78ce,
    name: "events-and-webinars-slug___nl"
  }, {
    path: "/events-and-webinars/:slug?",
    component: _3f8a78ce,
    name: "events-and-webinars-slug___en-IN"
  }, {
    path: "/events-und-webinars/:slug",
    component: _3f8a78ce,
    name: "events-and-webinars-slug___de"
  }, {
    path: "/formateurs/:slug",
    component: _1e3eeeae,
    name: "trainers-slug___fr"
  }, {
    path: "/formations/:slug",
    component: _11e342da,
    name: "courses-slug___fr"
  }, {
    path: "/trainer/:slug",
    component: _1e3eeeae,
    name: "trainers-slug___de"
  }, {
    path: "/trainers/:slug",
    component: _1e3eeeae,
    name: "trainers-slug___en"
  }, {
    path: "/trainers/:slug",
    component: _1e3eeeae,
    name: "trainers-slug___nl"
  }, {
    path: "/trainers/:slug",
    component: _1e3eeeae,
    name: "trainers-slug___en-IN"
  }, {
    path: "/trainingen/:slug",
    component: _11e342da,
    name: "courses-slug___nl"
  }, {
    path: "/trainings/:slug",
    component: _11e342da,
    name: "courses-slug___de"
  }, {
    path: "/articles/:category/:slug",
    component: _24842be7,
    name: "knowledge-category-slug___fr"
  }, {
    path: "/courses/:category/:slug",
    component: _17f82821,
    name: "courses-category-slug___en"
  }, {
    path: "/courses/:category/:slug?",
    component: _17f82821,
    name: "courses-category-slug___en-IN"
  }, {
    path: "/formations/:category/:slug",
    component: _17f82821,
    name: "courses-category-slug___fr"
  }, {
    path: "/knowledge/:category/:slug",
    component: _24842be7,
    name: "knowledge-category-slug___en"
  }, {
    path: "/knowledge/:category/:slug",
    component: _24842be7,
    name: "knowledge-category-slug___nl"
  }, {
    path: "/knowledge/:category/:slug",
    component: _24842be7,
    name: "knowledge-category-slug___de"
  }, {
    path: "/knowledge/:category/:slug?",
    component: _24842be7,
    name: "knowledge-category-slug___en-IN"
  }, {
    path: "/trainingen/:category/:slug",
    component: _17f82821,
    name: "courses-category-slug___nl"
  }, {
    path: "/trainings/:category/:slug",
    component: _17f82821,
    name: "courses-category-slug___de"
  }, {
    path: "/*",
    component: _239df27f,
    name: "all___en-IN"
  }, {
    path: "/*",
    component: _239df27f,
    name: "all___de"
  }, {
    path: "/*",
    component: _239df27f,
    name: "all___fr"
  }, {
    path: "/*",
    component: _239df27f,
    name: "all___nl"
  }, {
    path: "/*",
    component: _239df27f,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
