export default {
    submit: 'Envoyer',
    home: {
        courses: 'Formations',
        course: 'Formation',
        'courses-link': 'Formations',
        'all-courses-link': 'Toutes les formations',
        'all-trainers-link': 'Tous les formateurs'
    },
    course: {
        'enroll-today': 'S\'inscrire à une formation',
        'request-quote': 'Demander un devis',
        'download-brochure': 'Télécharger la brochure',
        'participant-score': 'Note des participants',
        'success-rate': 'Taux de succès',
        'cta-title': 'Vous ne savez pas si ce cours vous convient?',
        'cta-body': '{name}, notre conseillère en formation est à disposition pour vous aider.'
    },
    checkout: {
        title: 'Terminez votre inscription',
        'remove-participant': 'Retirer un participant',
        'add-participant': 'Ajouter un participant',
        'validation-notice': 'Veuillez remplir tous les champs avant de passer à l\'étape suivante.',
        'payment-method-notice': 'Veuillez choisir un mode de paiement avant de compléter votre inscription',
        required: 'Ce champ est obligatoire',
        error: {
            title: 'An error has occurred.',
            body: 'Nous ne pouvons pas traiter cette commande pour le moment, veuillez rafraîchir la page et réessayer. Vous pouvez également nous contacter pour obtenir de l\'aide et nous vous aiderons dès que possible.'
        },
        labels: {
            'first-name': 'Prénom',
            'last-name': 'Nom',
            email: 'Adresse email',
            phone: 'Numéro de téléphone',
            'customer-type': 'Vous êtes:',
            'type-corporate': 'Une entreprise',
            'type-personal': 'Un particulier',
            country: 'Pays',
            state: 'State',
            zip: 'Code Postal',
            city: 'Ville',
            address: 'Adresse',
            company: 'Nom de l\'entreprise',
            'job-title': 'Fonction',
            vat: 'Numéro de TVA',
            reference: 'Numéro de PO (si applicable)'
        },
        placeholders: {
            'first-name': 'Rémi',
            'last-name': 'Martin',
            email: 'remi@martin.com',
            phone: '+33 06 12345678',
            country: 'France',
            zip: '75001',
            city: 'Paris',
            address: '21, rue de l\'agilité',
            company: 'Entreprise',
            'job-title': 'Scrum Master',
            vat: '123456789B01',
            'select-country': 'Sélectionnez le pays',
            'select-state': 'Select state',
            reference: '123456789',
            coupon: 'Coupon code'
        },
        client: {
            title: 'Participant',
            'button-next': 'Suivant : Informations de facturation'
        },
        'special-offer': {
            title: 'Offre spéciale',
            'button-next': 'Suivant: Offre spéciale',
        },
        billing: {
            title: 'Informations de facturation',
            'button-next': 'Suivant : Paiement',
            'reuse-information': 'Utiliser les coordonnées du premier participant'
        },
        payment: {
            title: 'Paiement',
            'button-next': 'Confirmez votre inscription',
            'adyen-submit': 'Confirmez votre paiement',
            failed: 'Le paiement a échoué',
            'failed-message': 'Essayez à nouveau ou avec une méthode de paiement différente',
        },
        'order-summary': {
            'more-info': 'Plus d\'informations',
            'less-info': 'Moins d\'informations',
            trainers: 'Formateurs',
            participant: 'participant',
            participants: 'participants',
            vat: 'TVA',
            'calculation-notice': 'Calcul à la prochaine étape',
            total: 'Total',
            'add-coupon': 'Ajouter un code promo',
            'cancel-coupon': 'Annuler le code promo',
            'apply-coupon': 'Appliquer le code promo',
            'invalid-coupon': 'Code promo non valide'
        },
        statuses: {
            success: {
                title: 'Super! Votre commande est confirmée',
                description: 'Merci pour votre commande. Vous allez recevoir la confirmation de commande et votre facture par email dans quelques instants.',
            },
            error: {
                title: 'Le paiement a échoué',
                description: 'Essayez à nouveau ou contactez-nous pour obtenir de l\'aide. ',
            },
            pending: {
                title: 'En attente de paiement',
                description: 'Nous n\'avons pas encore reçu la confirmation de votre paiement. Dès que nous l\'aurons reçu, vous recevrez une confirmation et votre facture par email. Si vous avez annulé le paiement ou si vous avez rencontré une erreur, essayez à nouveau ou contactz-nous pour obtenir de l\'aide',
            },
            loading: {
                title: 'En attente de paiement',
                description: 'Patientez un instant.',
            },
        }
    },
    editions: {
        title: 'Sessions de formations',
    },
    'stat-list': {
        'succesful-alumni': 'Participants satisfaits',
        'market-leader': 'Leader sur le marché des formations SAFe',
        'certified-trainers': 'Formateurs certifiés',
    },
    schedule: {
        title: 'Calendrier',
        results: 'Résultats',
        result: 'Résultat',
        'loading-results': 'Chargement des résultats...',
        'no-results': 'Aucun résultat trouvé,',
        'clear-filters': 'réinitialiser les filtres',
        'to-show-all-options': 'pour montrer toutes les options',
        programs: 'Programmes',
        filter: 'Filtre',
        courses: 'Formations',
        'start-date': 'Date de début',
        location: 'Lieu',
        language: 'Langue',
        attendance: 'Type de formation',
        'show-next': 'Montrer les {count} prochaines',
        'of-total': 'sur {count} sessions.',
        'ex-vat': 'H.T*',
        'vat-text': '*Taux legal de la TVA en France 20%',
        enroll: 'S\'inscrire'
    },
    trainer: {
        label: 'Formateur certifié'
    },
    nav: {
        question: 'Une question?',
        currency: 'Devise',
        'schedule-meeting': 'Planifier un RDV',
        language: 'Changer de langue',
        nl: 'Néerlandais',
        en: 'Anglais',
        de: 'Allemand',
        fr: 'Français'
    },
    footer: {
        copyright: '© {year} Gladwell Academy Tous droits réservés. Paiement sécurisé par',
        courses: 'Formations',
        'gladwell-academy': 'Gladwell Academy',
        'knowledge-hub': 'Ressources',
    },
    cookiebar: {
        title: 'Cookies',
        message: 'Nous utilisons des cookies pour améliorer votre expérience de navigation sur notre site web. Les cookies sont utilisés pour la personnalisation des annonces. Pour plus d\'informations, consultez notre {privacy}',
        link: 'Politique de confidentialité',
        saving: 'Enregistrement en cours...',
        'configure-preferences': 'Préférences',
        accept: 'Accepter les cookies',
        preferences: {
            title: 'Préférences en matière de cookies',
            description: 'Vous pouvez configurer ci-dessous vos préférences en matière de cookies. Les cookies techniquement requis sont essentiels au bon fonctionnement du site web. Nous n\'utilisons d\'autres cookies ou technologies que si nous avons votre consentement préalable. Vous avez la possibilité de vérifier et de modifier vos paramètres à tout moment. Vous trouverez des informations détaillées sur ce {link}.',
            link: 'Politique de confidentialité',
            subtitle: 'Configurez vos préférences',
            confirm: 'En fermant cette fenêtre, vous acceptez également les paramètres de confidentialité actuels. Cliquez sur "OK" pour accepter, ou sur "Annuler" pour effectuer des modifications.',
            save: 'Sauvegarder les préférences'
        },
        functional: {
            title: 'Cookies fonctionnels',
            description: 'qui sont nécessaires et indispensables au bon fonctionnement du site web.'
        },
        analytical: {
            title: 'Cookies analytiques',
            description: 'que nous utilisons pour mieux comprendre comment les visiteurs utilisent le site web.'
        },
        marketing: {
            title: 'Cookies de suivi',
            description: 'que nous utilisons pour mieux comprendre le comportement d\'un visiteur sur un ou plusieurs domaines.'
        }
    },
    'not-found': {
        title: 'Il manque quelque chose...',
        message: 'Nous ne trouvons pas la page que vous cherchez.'
    },
    error: {
        title: 'Il y a eu un problème...',
        message: 'On dirait qu\'il y a eu un problème...'
    },
    'not-available-in-language': {
        title: 'Pas disponible dans cette langue',
        description: 'Cette page n\'est pas disponible dans la langue que vous avez sélectionnée.',
        instruction: 'Veuillez revenir à la',
        home: 'page d\'accueil',
        or: 'ou',
        'go-back': 'retourner'
    },
    thankyou: {
        title: 'Merci!',
        message: ''
    },
    form: {
        'select-option': 'Sélectionnez l\'option',
    },
    event: {
        'get-tickets': 'Acheter des tickets',
        'get-tickets-now': 'Acheter des tickets',
        'read-more': 'Lire plus'
    },
    edition: {
        trainers: {
            title: 'Vos formateurs'
        },
        'training-dates': {
            title: 'Dates de la formation'
        },
        'more-info': 'Plus d\'informations',
        'show-more': 'Show more',
        'show-less': 'Show less',
        usp: {
            title: 'Pourquoi choisir Gladwell?',
            first: 'Leader sur le marché des formations SAFe en Europe',
            second: 'Des formateurs certifiés avec une forte expérience terrain',
            third: 'Scaled Agile Gold Partner'
        },
        'download-brochure': 'Télécharger la brochure',
        brochure: 'Brochure',
        'enroll-now': 'S\'inscrire',
        conjunction: 'et'
    },
    knowledge: {
        'written-by': 'Écrit par',
        search: 'Rechercher',
        categories: 'Catégories',
        all: 'Tout',
    }
};
